import "./index.css";

import { RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Account/Login";
import ForgetPassword from "./pages/Account/ForgetPassword";
import AuthLayout from "./layouts/AuthLayout";
import ResetPassword from "./pages/Account/ResetPassword";
import VerifyOtp from "./pages/Account/VerifyOtp";

const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/auth-login",
        element: <Login />,
      },
      {
        path: "/auth-login/forgot-password",
        element: <ForgetPassword />,
      },
      {
        path: "/auth-login/verify-otp",
        element: <VerifyOtp />,
      },
      {
        path: "/auth-login/reset-password",
        element: <ResetPassword />,
      },
    ],
  },
]);

export default function Root() {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer autoClose={1000} />
    </>
  );
}
