import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

import DataLoadSpinner from "../../components/Reusable/Spinner";
import { useEffect, useState } from "react";
const VerifyOtpComponent: any = Loadable({
  loader: () => import("../../components/Account/VerifyOtp"),
  loading: DataLoadSpinner,
});
const VerifyOtp = () => {
  const navigate = useNavigate();
  const [time, setTime] = useState(30);

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [time]);

  const handleResendOpt = async () => {
    const email = JSON.parse(sessionStorage.getItem("email"));
    try {
      const response = await fetch(
        "https://usermanagement.dibsolutions.com.au/api/user/send-otp/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(email),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || "Please try again later");
      }
      setTime(30);
      toast.success("OTP sent successfully");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleVerifyOTP = async (data: any) => {
    try {
      const response = await fetch(
        "https://usermanagement.dibsolutions.com.au/api/user/verify-otp/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData?.otp?.[0] || "Please try again later");
      }
      toast.success("Verify OTP successfully");
      navigate("/auth-login/reset-password");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <VerifyOtpComponent
        handleVerifyOTP={handleVerifyOTP}
        handleResendOpt={handleResendOpt}
        time={time}
      />
    </>
  );
};

export default VerifyOtp;
