import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import DataLoadSpinner from "../../components/Reusable/Spinner";

const ResetPasswordComponent: any = Loadable({
  loader: () => import("../../components/Account/ResetPassword"),
  loading: DataLoadSpinner,
});

const ResetPassword = () => {
  const navigate = useNavigate();

  const handleResetPassword = async (data: any) => {
    const { email } = JSON.parse(sessionStorage.getItem("email"));
    data.email = email;
    try {
      const response = await fetch(
        "https://usermanagement.dibsolutions.com.au/api/user/forget-password/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        toast.success("Password reset successfully");
        navigate("/auth-login");
      } else {
        if (responseData?.otp) {
          toast.error(responseData?.otp[0]);
        } else if (responseData?.non_field_errors) {
          toast.error(responseData?.non_field_errors[0]);
        } else if (responseData?.details) {
          toast.error(responseData?.details);
        } else {
          toast.error("Failed to reset password");
        }
      }
    } catch (error) {
      toast.error("An error occurred while resetting password");
      console.error(error);
    }
  };

  return (
    <>
      <ResetPasswordComponent handleResetPassword={handleResetPassword} />
    </>
  );
};

export default ResetPassword;
